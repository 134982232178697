let navContent = document.querySelector('.nav-content');
let navbar = document.querySelector('.navbar');
let mobileBtn = document.querySelector('.mobile-btn');
let closeMobile = document.querySelector('.close-navbar');
let shadow = document.querySelector('.shadow');

mobileBtn.addEventListener('click', () => {
    navContent.classList.add('active'); 
    navbar.classList.add('active');
    shadow.classList.add('active');
    isTrusid(); 
});

closeMobile.addEventListener('click', () => {
    navContent.classList.remove('active'); 
    navbar.classList.remove('active');
    shadow.classList.remove('active');
    isTrusid(); 
});

shadow.addEventListener('click', () => {
    navContent.classList.remove('active'); 
    navbar.classList.remove('active');
    shadow.classList.remove('active');
    isTrusid(); 
});

function isTrusid() {
    if (navContent.classList.contains('active')) {
        navContent.style.transition = 'none';
    
    } else {
        navContent.style.transition = 'all .4s ease';
    }
}

window.addEventListener('scroll', () => {
    navContent.classList.toggle('fixed', window.scrollY > 200);
    isTrusid();
});

// // add 4 <span> elements with specific classes to each "a.button-line" element
function handleAddBtnSpan() {
    const buttons = document.querySelectorAll("a.button-line");
    buttons.forEach((button) => {
      for (let i = 0; i < 4; i++) {
        const span = document.createElement("span");
    
        span.classList.add(`extra-span-${i + 1}`);
        button.appendChild(span);
      }
    });
}

function headerReview() {
    let headerReviews = document.querySelectorAll('.header-reviews img');
    let headerReviewBtn = document.querySelector('.text-review');
    let headerReviewToolTip = document.querySelector('.review-tooltip');

    headerReviews.forEach((img, index) => {
        let getIndex = index * 10;
        img.style.left = `${-getIndex}px`;
        img.style.opacity = `0.${index+5}`;
        if (index >= 5) img.remove();
    });

    function isActive(value) {
        if(value) {
            headerReviewToolTip.style.display = 'block';
            setTimeout(() => {
                headerReviewToolTip.classList.add('active');
            }, 200);
        } else {
            headerReviewToolTip.classList.remove('active');
            setTimeout(() => {
                headerReviewToolTip.style.display = 'none';
            }, 200);
        }
    }

    headerReviewBtn.addEventListener('mouseover', (e) => {
        headerReviewToolTip.style.left = e.clientX + 'px';
        headerReviewToolTip.style.top = e.clientY + 'px';
        isActive(true);
    });

    headerReviewBtn.addEventListener('mouseleave',(e) => {
        headerReviewToolTip.style.left = e.clientX + 'px';
        headerReviewToolTip.style.top = e.clientY + 'px';
        isActive(false);
    });
}

isTrusid();
headerReview();
handleAddBtnSpan();


let TrandingSlider = new Swiper('.tranding-slider', {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    loop: true,
    slidesPerView: 'auto',
    coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 2.5,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
});